import React from 'react'

import Icon from '../data/icon.png';
const Entete = () => {
    return (
        <div className="flex justify-between gap-3">
            <div className="flex flex-col gap-3">
                <h1>Repulique Islamique de Mauritanie</h1>
                <h1>Ecole privées Ahmed Deina Fall</h1>
                <h2>Tel: 27111108 41322928</h2>
            </div>
            <img className="mx-auto h-28 w-auto" src={Icon} alt="Your Company" />
            <div className="flex flex-col text-right gap-3">
                <h1>الجمهوريــة الإسلاميــة الموريتـانيــة</h1>
                <h1>مدارس أحمد دينا افال الحرة</h1>
                <h2> 27111108 41322928: الهاتف</h2>
            </div>
        </div>
    )
}

export default Entete